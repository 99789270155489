import React from "react";

import { Col, Row, Typography } from "antd";

function IntroCard() {
  return (
    <>
      <Row align="middle" justify="start" gutter={[4, 4]}>
        <Col>
          <Typography.Title style={{ fontSize: "1.4em" }}>
            gRPC-Web Demo
          </Typography.Title>
        </Col>
      </Row>
      <Row>
        <Typography.Text>
          Pick an
          <b style={{ margin: "0 4px" }}>
            <i>impossible</i>
          </b>
          catalog item to begin
        </Typography.Text>
      </Row>
    </>
  );
}

export default IntroCard;
