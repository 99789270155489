import React from "react";
import ReactCanvasConfetti from "react-canvas-confetti";

const canvasStyles = {
  position: "fixed",
  pointerEvents: "none",
  width: "100%",
  height: "100%",
  top: "-40%",
  left: 0,
};

export default class Realistic extends React.Component {
  constructor(props) {
    super(props);
    this.animationInstance = null;
  }

  makeShot = (particleRatio, opts) => {
    this.animationInstance &&
      this.animationInstance({
        ...opts,
        origin: { y: 1 },
        particleCount: Math.floor(300 * particleRatio),
      });
  };

  fire = () => {
    this.makeShot(1, {
      spread: 260,
      scalar: 1,
      startVelocity: 90,
    });

    this.makeShot(0.75, {
      spread: 170,
      scalar: 2,
      startVelocity: 80,
    });

    this.makeShot(0.5, {
      spread: 80,
      scalar: 1.5,
      startVelocity: 70,
    });

    this.makeShot(0.25, {
      spread: 120,
      scalar: 2,
      startVelocity: 60,
    });
  };

  getInstance = (instance) => {
    this.animationInstance = instance;
  };

  componentDidMount() {
    this.fire();
  }

  render() {
    return (
      <ReactCanvasConfetti
        gravity={0.5}
        refConfetti={this.getInstance}
        style={canvasStyles}
      />
    );
  }
}
